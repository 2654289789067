import { getInfo, setInfo } from '@/utils/auth.js'
import {commit} from "lodash/seq";

const common = {
    state: {
        info: getInfo(), //  首页数据信息
    },
    mutations: {
        SET_INFO: (state, info) => {
            state.info = info
            setInfo(info)
        },
    },
    actions: {

    }
}

export default common
