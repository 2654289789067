// 获取设置cookies
const infoKey = 'vixh-info'


// 信息
export function getInfo() {
  let dataStr = localStorage.getItem(infoKey)
  if(dataStr) {
    return JSON.parse(dataStr)
  }
  return {}
}

export function setInfo(info) {
    return localStorage.setItem(infoKey, JSON.stringify(info))
}

export function removeInfo() {
    return localStorage.removeItem(infoKey)
}
