<template>
  <div class="page-container" :class="showFull ? 'hidden' : ''">
    <m-nav></m-nav>

    <!-- 第一个区域 banner -->
    <div class="area1 banner-container">
      <div class="area1-banner-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in area1bannerList" :key="index">
          <div class="img" :style="{backgroundImage: `url(${item.url})`}"></div>
        </div>
        </div>
      </div>
    </div>

    <!-- 第二个区域 -->
    <div class="area2">
      <!-- banner -->
       <div class="area2-banner-wrap">
        <div class="area2-banner-swiper-container swiper-container show-pagination-dot">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in area2bannerList" :key="index">
              <div class="img" :style="{backgroundImage: `url(${item.url})`}"></div>
            </div>


          </div>

          <div class="swiper-pagination"></div>
        </div>
       </div>

      <!-- 介绍 -->
      <div class="introduction">
        <div class="title">{{ data.IntroTitle }}</div>
        <div class="sub-title">XING HE SHANG PIN</div>
        <div class="text">
          {{ data.Introduction }}
        </div>
      </div>
    </div>

    <!-- 第三个区域 -->
    <div class="area3">
      <div class="bg">
        <div class="title">{{ card1.title }}</div>
        <div class="sub-title text-line1">{{  card1.subTitle }}</div>
      </div>

      <div class="area3-banner-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in area3bannerList" :key="index">
            <div class="block">
              <div class="img" :style="{backgroundImage: `url(${item.image})`}"  :data-v="JSON.stringify(item)"></div>
              <div class="text text-line1"  :data-v="JSON.stringify(item)">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 第四个区域 -->
    <div class="area4">
      <div class="title">{{ card2.title }}</div>
      <div class="person-container">
        <div class="block" v-for="(item, index) in personList" :key="index">
          <div class="img" :style="{backgroundImage: `url(${item.image})`}"></div>
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
    </div>

    <!-- 第五个区域 -->
    <!-- <div class="area5">
      <div class="article-block">
        <div class="title">
          <div class="text">大标题大标题 </div>
          <div class="arrow"></div>
        </div>
        <div class="detail">
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
        </div>
        <div class="more">READ MORE ></div>
      </div>
      <div class="article-block">
        <div class="title">
          <div class="text text-line1">大标题大标题 </div>
          <div class="arrow"></div>
        </div>
        <div class="detail">
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
          <div class="text text-line1">2024-06-18   标题标题标题标题标题标题标题标题标题标题标题标</div>
        </div>
        <div class="more">READ MORE ></div>
      </div>
    </div> -->
    <div style="height: 5vw"></div>

    <!-- 第六个区域 -->
     <div class="area6">
      <div class="left-block">
        <div class="text">与我们一起</div>
        <div class="text">在星河尚品创造奇迹！</div>
        <div class="tel-button">
          <div class="tel-icon"></div>
          <div class="tel-text">0579-82871882</div>
        </div>
      </div>
      <div class="right-block">
        <div class="img" v-if="data.layerUrl" :style="{backgroundImage: `url(${data.layerUrl})`}"></div>
      </div>
     </div>

     <m-footer></m-footer>


    <!-- 弹框 -->
    <div class="full-container" v-if="showFull" @click="onCloseFull">
      <div class="block" @click.stop>
        <div class="big-img" :style="{backgroundImage: `url(${currentSelect.url})`}"></div>
        <div class="text">{{ currentSelect.name }}</div>
      </div>

    </div>
  </div>
</template>

<script>

import mNav from '@/components/mNav.vue'
import mFooter from '@/components/mFooter.vue'
import Swiper from "swiper"
import "swiper/css/swiper.min.css";
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
    mNav,
    mFooter
  },

  data() {
    return {
      area1bannerList: [],
      area2bannerList: [],
      area3bannerList: [],
      card1: {
        title: '',
        subTitle: ''
      },
      card2: {
        title: ''
      },

      showFull: false,
      currentSelect: {
        url: '',
        name: ''
      },
      area1Banner: null,
      area2Banner: null,
      area3Banner: null,

      personList: [],

      data: {}
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    getData() {
      axios.get('https://tuan.vaixh.com/api/system/system/config/get-by-key?configKey=tuan.official.config').then(res => {
        if(res.data.data) {
          let data = JSON.parse(res.data.data)
          this.data = data

          this.$store.commit('SET_INFO', data)
          // 第一区域
          this.area1bannerList = []
          data.headBanner.forEach(element => {
            this.area1bannerList.push({
              url: element
            })
          });

          // 第二区域
          this.area2bannerList = []
          data.banner.forEach(element => {
            this.area2bannerList.push({
              url: element
            })
          });

          // 第三区域
          this.card1.title = data.card1.title
          this.card1.subTitle = data.card1.subTitle
          this.area3bannerList = []
          data.card1.bannerList.forEach(element => {
            this.area3bannerList.push(element);
          })

          // 第四区域
          this.card2.title = data.card2.title
          this.personList = []
          data.card2.bannerList.forEach(element => {
            this.personList.push(element)
          })

          console.log(data)

          setTimeout(() => {
            this.initArea1Banner()
            this.initArea2Banner()
            this.initArea3Banner()

            this.startWindowListener()
          }, 50)
        }
      })
    },

    initArea1Banner() {
        this.area1Banner = new Swiper(".area1-banner-swiper-container", {
          loop: true,
          effect : 'fade',
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
    },

    initArea2Banner() {
        this.area2Banner = new Swiper(".area2-banner-swiper-container", {
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            //type: 'fraction',
            //type : 'progressbar',
            //type : 'custom',
          },
        });
    },

    initArea3Banner() {
        this.area3Banner = new Swiper(".area3-banner-swiper-container", {
          loop: true,
          slidesPerView : 4,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          on: {
            click: (e) => {
              console.log(11)
              console.log(e.target)
              if(e.target.getAttribute('data-v')) {
                let obj = JSON.parse(e.target.getAttribute('data-v'))
                this.selectItem(obj)
              }
            }
          }
        });
    },

    startWindowListener() {
      window.onresize = () => {
        this.area1Banner.updateSize();
        this.area2Banner.updateSize();
        this.area3Banner.updateSize();
        this.$forceUpdate()
      }

    },

    selectItem(data) {
      console.log('点开')
      this.currentSelect.url = data.image || ''
      this.currentSelect.name = data.title || ''
      // this.area3Banner.disable(); 版本不对
      this.showFull = true
    },

    onCloseFull() {
      this.showFull = false
      // this.area3Banner.enable();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #F1F3F1;

  &.hidden {
    overflow-y: hidden;
  }

  .banner-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .swiper-container {
      height: 100%;

      .swiper-slide {
        height: 100%;

        .img {
          height: 100%;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }

  .area2 {
    width: 100%;
    min-height: 27.81vw;
    position: relative;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;

    .area2-banner-wrap {
      position: absolute;
      top: 5vw;
      left: 18vw;
      width: 41vw;
      height: 27.5vw;
      z-index: 3;
    }

    .area2-banner-swiper-container {
      width: 100%;
      height: 100%;

      .swiper-wrapper {
        height: 100%;


        .swiper-slide {
          height: 100%;
          background-color: antiquewhite;

          .img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
          }
        }
      }

      .swiper-pagination {
        position: absolute;
          left: 0;
          right: 0;
          bottom: 0.65vw;

        ::v-deep  .swiper-pagination-bullet {
            width: 0.5vw;
            height: 0.5vw;
            border: 1px solid #fff;
            border-radius: 50%;
            background-color: transparent;
            opacity: 1;
            z-index: 8;

            &.swiper-pagination-bullet-active {
              background-color: #fff;
            }
          }
        }


    }

    .introduction {
      margin-top: -2.5vw;
      position: relative;
      width: 45.1%;
      box-sizing: border-box;
      padding: 3vw 18.7vw 4vw 7.5vw;
      background-color: #95D42F;
      z-index: 2;
      line-height: 1.45vw;
      font-size: 0.83vw;
      color: #fff;

      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 2.5vw;
        font-size: 1.875vw;
        text-align: right;
        color: #FFFFFF;

        .text {
          max-width: 10vw;
        }
      }

      .sub-title {
        line-height: 1.5vw;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 1.25vw;
        text-align: right;
        color: #C2E787;
      }

      .text {
        margin-top: 1.5vw;
        text-align: right;
      }


    }
  }

  .area3 {
    text-align: left;

    .bg {
      width: 100%;
      height: 22.7vw;
      padding:1vw 18.75vw;
      box-sizing: border-box;
      background-image: url('../assets/image/area3_bg.png');

      .title {
        line-height: 7vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 4.167vw;
        color: #FFFFFF;
      }

      .sub-title {
        line-height: 2.5vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 1.875vw;
        color: #95D42F;
      }
    }

    .area3-banner-swiper-container {
      margin: -10vw auto 0;
      width: calc(100% - 4.48vw);

      .block {
        width: 22vw;
        height: 16.15vw;
        box-sizing: border-box;
        padding-top: 1vw;
        background: #95D42F;
        border-radius: 8px;
        cursor: pointer;

        .img {
          margin: 0 auto;
          width: 20.5vw;
          height: 11.25vw;
          background-size: cover;
        }

        .text {
          width: 100%;
          box-sizing: border-box;
          padding: 0.75vw;
          line-height: 1.3vw;
          font-size: 0.83vw;
          color:#fff;
        }
      }
    }
  }

  .area4 {
    padding-top: 1vw;
    .title {
      line-height: 6vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      text-align: center;
      font-size: 2.5vw;
      color: #95D42F;
    }

    .person-container {
      display: flex;
      justify-content: center;

      .block {
        margin-right: 0.88vw;
        width: 14.9vw;
        border-radius: 0.3vw;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s linear;

        .img {
          width: 14.9vw;
          height: 14.9vw;
          background-size: cover;
        }

        .text {
          height: 6.09vw;
          box-sizing: border-box;
          padding: 1vw 1vw;
          background-color: #95D42F;
          line-height: 1.2vw;
          font-size: 0.83vw;
          color: #fff;
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &:hover {
          transform: translateY(-1vw);
        }
      }
    }
  }

  .area5 {
    width: 100%;
    height: 26.7vw;
    display: flex;
    justify-content: center;
    background-color: #fff;

    .article-block {
      position: relative;
      padding: 1.6vw 5.8vw;

      .title {
        display: flex;
        align-items: center;
        text-align: left;
        height: 3.7vw;
        position: relative;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.3vw;
        color: #000000;

        .arrow {
          margin-left: 1.4vw;
          display: inline-block;
          width: 0.9vw;
          height: 1.4vw;
          background-image: url('../assets//image/arrow.png');
          background-size: 100% 100%;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 4.8vw;
          height: 0.3vw;
          background: #95D42F;
        }
      }

      .detail {
        padding-top: 0.3vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 0.8vw;
        color: #6B6B6B;
        line-height: 2.9vw;
      }

      .more {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 0.8vw;
        color: #95D42F;
        line-height: 3.1vw;
      }

      &::after {
        content: '';
        position: absolute;
        top: 5vw;
        right: 0;
        width: 1px;
        height: 18.7vw;
        background-color: #95D42F;
      }

      &:last-child {
        &::after {
          background-color: transparent;
        }
      }
    }
  }


  .area6 {
    padding-left: 19vw;
    display: flex;

    .left-block {
      padding-top: 2.5vw;
      .text {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 3.1vw;
        color: #95D42F;
        line-height: 3.6vw;
      }

      .tel-button {
        margin-top: 2vw;
        width: 18.9vw;
        height: 3.7vw;
        background: #95D42F;
        display: flex;
        justify-content: center;
        align-items: center;

        .tel-icon {
          margin-right: 0.8vw;
          width: 2.1vw;
          height: 2vw;
          background-image: url('../assets//image/tel.png');
          background-size: 100% 100%;
        }

        .tel-text {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 1.9vw;
          color: #FFFFFF;
        }
      }
    }

    .right-block {
      .img {
        margin-top: -2vw;
        width: 31.1vw;
        height: 22.3vw;
        background-size: cover;
        pointer-events: none;
      }
    }
  }

}


.full-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  .block {
    box-shadow: 0 12px 40px 0 rgba(90, 90, 90, 0.5);
    background-color: #fff;

    .big-img {
      width: 62.5vw;
      height: 33.75vw;
      background-size: cover;
    }

    .text {
      width: 100%;
      box-sizing: border-box;
      padding: 2.25vw;
      line-height: 3.9vw;
      font-size: 2.5vw;
      color:#fff;
    }
  }


}
</style>
