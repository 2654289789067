<template>
  <div class="footer-container">
      <div class="inner">
        <div class="logo"></div>

        <div class="main">

          <div class="contact">
            <div>
              <div>联系电话：0579-82871882</div>
              <div>联系邮箱：fc124414367@sina.com</div>
              <div>联系地址：金华市金衢路3号智诚大厦6幢709室-2</div>
            </div>
            <div class="app"></div>
          </div>

          <div class="href-wrap">
            <a class="weight" href="javascript:void(0);">公司介绍</a>
            <a href="javascript:void(0);">网站首页</a>
            <a href="javascript:void(0);">百万单品</a>
            <a href="javascript:void(0);">品牌文化</a>
            <a href="javascript:void(0);">加入我们</a>
          </div>

          <div class="href-wrap">
            <a class="weight" href="javascript:void(0);">品牌招商</a>
            <a href="javascript:void(0);">品牌授权</a>
            <a href="javascript:void(0);">供货招商</a>
            <a href="javascript:void(0);">经销商认证</a>
            <a class="normal" href="https://tuan.vaixh.com/backend/login" target="_blank">后台入口</a>
          </div>

          <div class="href-wrap">
            <div class="weight">社交网站</div>

            <a href="javascript:void(0);">微信公众号：星河尚品</a>
          </div>

        </div>

        <div class="right">
          © 星河潮玩（浙江）网络科技有限公司 版权所有Copyright 2023 www.vaixh.com All Rights Reserved |
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=浙公网安备33071802889155号" target="_blank"> <img src="../assets/image/guohui.png" style="width: 0.7vw;margin-bottom: -0.1vw;" /> 浙公网安备33071802889155号</a> |
          <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023022259号-1</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MFooter',

  data () {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.footer-container {
  font-family: Microsoft YaHei;
  width: 100%;
  height: 27.7vw;
  box-sizing: border-box;
  padding: 3.7vw 0 0 18.7vw;
  background: #95D42F;
  color:#fff;

  .inner {
    height: 100%;

    .logo {
      margin-bottom: 1vw;
      width: 14.8vw;
      height: 4vw;
      background-image: url('../assets/image/logo.png');
      background-size: 100% 100%;
    }

    .main {
      position: relative;
      display: flex;
      padding-bottom: 3vw;

      .contact {
        width: 33.9vw;
        font-size: 0.8vw;
        line-height: 1.9vw;

        .app {
          margin-top: 1.2vw;
          width: 14.7vw;
          height: 2.8vw;
          background-image: url('../assets/image/app_icon.png');
          background-size: 100% 100%;
        }
      }

      .href-wrap {
        padding-right: 5.5vw;
        line-height: 1.9vw;
        font-size: 0.8vw;
        color: #fff;

        a {
          display: block;
          text-decoration: none;
          font-size: 0.8vw;
          color: #fff;
        }

        .weight {
          font-weight: bold;
        }

        .normal {
          &:hover {
            color: #355303;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 62.5vw;
        height: 1px;
        background-color: #fff;
      }
    }

    .right {
      padding-top: 2vw;
      line-height: 1.9vw;
      font-size: 0.8vw;
      font-weight: bold;
      color: #fff;

      a {
        text-decoration: none;
        font-size: 0.8vw;
        color: #fff;

        &:hover {
          color: #355303;
        }
      }
    }

  }
}
</style>
