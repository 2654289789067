<template>
  <div class="nav-container">
      <div class="inner d-flex ali-center ju-between">
        <img class="logo" src="../assets/image/logo1.png" />

        <div class="nav d-flex">
          <div class="button d-flex ali-center" :class="activeIndex == index ? 'active' : ''" v-for="(item ,index) in list" :key="index">{{ item.name }}</div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MNav',

  computed: {
    activeIndex() {
      let index = this.list.findIndex(item => item.path == this.$route.path)
      return index > -1 ? index : 0
    }
  },

  data () {
    return {
        list: [
          {
            name: '首页',
            path: '/',
            index: 0
          },
          {
            name: '品牌故事',
            path: '/brand',
            index: 0
          },
          {
            name: '公司简介',
            path: '/introduction',
            index: 0
          }
        ],
        area1Banner: null
    }
  }
}
</script>

<style scoped lang="scss">
.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  height: 120px;
  z-index: 5;

  .inner {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    height: 100%;

    .logo {
      display: block;
      width: 74px;
      height: 74px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #f1f1f1;
    }

    .nav {
      height: 100%;

      .button {
        position: relative;
        padding: 0 3vw;
        height: 100%;
        cursor: pointer;
        font-size: 18px;
        color: #fff;

        &.active {
          color: #95D42F;

          &::after {
            content: '';
            position: absolute;
            left: 50%;
            right: 0;
            bottom: 0;
            width: calc(100% - 6vw);
            height: 2px;
            transform: translateX(-50%);
            background-color: #95D42F;
          }
        }

        &:hover {
          color: #95D42F;
        }
      }
    }
  }
}
</style>
